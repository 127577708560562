const theme = {
  colors: {
    base: '#ffffff', // White
    secondary: '#b3b3b3', // Medium Gray
    tertiary: '#f3f3f3', // Light Gray
    highlight: '#ff0000', // Red
    text: '#000000' //black
  },
  sizes: {
    maxWidth: '1050px',
    maxWidthCentered: '650px',
  },
  responsive: {
    small: '35em',
    medium: '50em',
    large: '70em',
  },
}

export default theme
